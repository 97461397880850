import * as React from 'react';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Document } from '@contentful/rich-text-types';
import CategoryLayout from '../components/CategoryLayout';
import { Disclosure } from '../models/Disclosure';
import { useDisclosure } from '../operations/queries';
import options from '../utils/getRichTextOptions';
import { Grid } from "@material-ui/core"

export default function DisclosurePage(): JSX.Element {
  const disclosure: Disclosure = useDisclosure();
  return (
    <CategoryLayout title="Disclosure">
      <Grid container spacing={4} style={{ marginBottom: 20 }}>
        {disclosure.texto && renderRichText(disclosure.texto, options)}
      </Grid>
    </CategoryLayout>
  );
}
